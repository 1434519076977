import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: './edit-module-dialog.component.html',
    styleUrls: ['./edit-module-dialog.component.scss']
})
export class EditModuleDialog {
    module: any = {};

    get isItemEmpty() {
        return !this.module.key || !this.module.name;
    }

    constructor(
        public dialogRef: MatDialogRef<EditModuleDialog>,
        public translate: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        data ??= {};
        // Copy the module object, so we don't edit it directly.
        this.module = {...data['module']} ?? {};
    }

    cancel() {
        this.dialogRef.close();
    }

    confirm() {
        this.dialogRef.close(this.module);
    }
}
