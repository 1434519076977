<h1 mat-dialog-title>{{ data.title | translate }}</h1>
<div mat-dialog-content class="mat-typography">
    <div class="flex flex-col gap-2">
        <div>
            <div class="mat-subtitle-2">{{ 'name' | translate }}</div>
            <sz-input [(ngModel)]="module.name" />
        </div>
        <div>
            <div class="mat-subtitle-2">{{ 'module.key' | translate }}</div>
            <sz-input [(ngModel)]="module.key" />
        </div>
    </div>
    <sz-dialog-button-bar
        (primaryClick)="confirm()"
        (secondaryClick)="cancel()"
        [primaryDisabled]="isItemEmpty"
    />
</div>
